

































































































































































































































































.upload-btn {
  height: 40px;
  /deep/.el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.form-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}
