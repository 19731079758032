.upload-btn {
  height: 40px;
}
.upload-btn /deep/ .el-upload {
  height: 40px !important;
  border: none !important;
}
.form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
